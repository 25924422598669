import { HttpInterceptorFn } from '@angular/common/http';

export const headerInterceptor: HttpInterceptorFn = (req, next) => {
  const userToken = localStorage.getItem('access_token');

  let modifiedRequest = req.clone({
    headers: req.headers
      .set('Authorization', `Bearer ${userToken}`)
      .set('Company', `${localStorage.getItem('selected_company')}`),
  });

  return next(modifiedRequest);
};
