import { ApplicationConfig, ErrorHandler, Provider, APP_INITIALIZER } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  provideRouter,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';
import { Router } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { headerInterceptor } from './core/interceptors/header.interceptor';
import { httpErrorInterceptor } from './core/interceptors/error.interceptor';
import * as Sentry from '@sentry/angular';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withViewTransitions(),
      withPreloading(PreloadAllModules)
    ),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([headerInterceptor, httpErrorInterceptor])
    ),
    /*{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },*/
  ],
};
